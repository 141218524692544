<template>
    <section class="autologout-wrapper">
        <div class="modal-container"  v-if="warningZone">
            
            <div class="modalAutoLogout" id="modalAutoLogout">
                <div class="grid-x">
                    <div class="cell small-12">
                        <div class="text-center">
                            <h2>Are you still there?</h2>
                            <a @click="getRefreshToken()" class="continue-link">Click to continue</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import $ from 'jquery';

import moment from 'moment';

export default {
    data: function () {
        return {
            events: ['click', 'mousemove', 'mousedown', 'scroll', 'keypress', 'load'],

            firstLoad: true,
            token: '',
            apiRefreshToken: '',
            username: '',
            timeRemaining: '',
            refreshTimeRemaining: '',

            warningTimer: null,
            logoutTimer: null,
            warningZone: false,
            updateRefreshToken: false,
        }
    },
    mounted: function () {
        this.trackEvents()
        //this.getRefreshToken();

        $(".autologout-wrapper").appendTo("body");

        let tokenInfo = { ...this.$cookies.get('TokenInfo') };
        this.apiRefreshToken = tokenInfo.RefreshToken.Token
        this.username = tokenInfo.User.Username
    },

    destroyed: function(){
        this.events.forEach( function (event){
            window.removeEventListener(event, this.resetTimer)
        }, this)

        this.resetTimer();

    },

    methods: {
        trackEvents(){
            this.events.forEach( function (event){
                window.addEventListener(event, this.resetTimer)
            }, this)

            this.setTimers();
        },

        getRefreshToken() {
            this.warningZone = false
            document.getElementsByClassName('continue-link')[0].classList.add("disabled-link");
      
            this.refreshToken();
        },
        calculateTimeRemaining () {
            let token = JSON.parse( sessionStorage.getItem('apiToken')),
                expire = moment(token.expires),
                refreshTime = moment(token.refreshToken.expires),
                currentTime = moment(new Date()),
                timeLeft = expire.diff(currentTime, 'minutes');
                
                // console.log(token);
                // console.log('Expiration: ' + expire);
                // console.log('Time Remaining: ' + timeLeft);

            this.timeRemaining = timeLeft;
            this.refreshTimeRemaining = refreshTime.diff(currentTime, 'minutes');

            return timeLeft;
        },
        refreshToken() {
            console.log('refresh token')

            let username = this.username,
                refreshToken = this.apiRefreshToken,
                protocol = this.getProtocol();


            

             let loginCreds = {
                "userName": username,
                "refreshToken": refreshToken
            }


            console.log(loginCreds)

            return fetch(protocol+process.env.VUE_APP_AUTHURL+'/1.0/Authentication/RefreshToken', {
                method: 'POST',
                body: JSON.stringify(loginCreds),
                headers: {
                    'Content-Type': 'application/json-patch+json'
                }
            })
            .then(response => response.json())
            .then((response) => {
                let res = response;
                this.setToken(res);
                this.updateRefreshToken = false;
                console.log(res)
            })
        },
        submitRefresh(response) {
            let tokenObj = {};
            tokenObj = response.data,
            tokenInfo = { ...this.$cookies.get('TokenInfo') };

            console.log(tokenObj)
            console.log(tokenInfo)
  

            if (tokenObj.token === null || tokenObj.token === undefined || tokenObj.token === "") {
                if (tokenObj.refreshToken.isExpired === true) {
                    console.log('Logout process..')
                    document.querySelector('.sign-out-button').click();
                } else {
                    console.log('Refresh token process...')
                    this.getRefreshToken();
                }
            } else {
                var tokenExpires = new Date(tokenObj.expires);
                tokenExpires = tokenExpires.toUTCString();

                this.setToken(tokenObj);
            }
        },
        setToken(data) {
            let tokenObj = data.data,
                tokenInfo = { ...this.$cookies.get('TokenInfo') };

            console.log(tokenObj)
            console.log(tokenInfo)

            if (tokenInfo) {
                tokenInfo.Token = tokenObj.token;
                tokenInfo.Message = tokenObj.message;
                tokenInfo.Expires = tokenObj.expires;
                tokenInfo.IsExpires = tokenObj.isExpired;
                tokenInfo.MinutesToExpiration = tokenObj.minutesToExpiration;
                tokenInfo.IsAuthenticated = tokenObj.isAuthenticated;
                tokenInfo.RefreshToken.Token = tokenObj.refreshToken.token;
                tokenInfo.RefreshToken.CurrentDatetime = tokenObj.refreshToken.currentDateTime;
                tokenInfo.RefreshToken.Expires = tokenObj.refreshToken.expires;
                tokenInfo.RefreshToken.IsExpired = tokenObj.refreshToken.isExpired;
                tokenInfo.RefreshToken.MinutesToExpiration = tokenObj.refreshToken.minutesToExpiration;
                tokenInfo.RefreshToken.Created = tokenObj.refreshToken.created;
                tokenInfo.RefreshToken.Revoked = tokenObj.refreshToken.revoked;
                tokenInfo.RefreshToken.IsActive = tokenObj.refreshToken.isActive;
                tokenInfo.RefreshToken.LastUpdated = tokenObj.refreshToken.lastupdated;

                sessionStorage.setItem('TokenInfo', JSON.stringify(tokenInfo), "/", tokenInfo.RefreshToken.Expires, false);

                sessionStorage.setItem("apiToken" , JSON.stringify(tokenObj) )
                sessionStorage.setItem("tokenExpires", JSON.stringify(tokenObj.expires) )
                
                let tokenInfoSetted = { ...this.$cookies.get('TokenInfo') };
                this.apiRefreshToken = tokenInfoSetted.RefreshToken.Token

                console.log("= TokenInfo =")
                console.log(tokenInfo)

                
            }  



            this.token = tokenObj;
            
            //this.getTokenTime();
        },

        setTimers() {
            this.warningTimer = setInterval(this.warningMessage, 45 * 1000)
            this.logoutTimer = setTimeout(this.logoutUser, 900 * 1000)           
        },

        warningMessage() {
            //this.warningZone = true
            let refreshTimeRemaining = this.calculateTimeRemaining();
            console.log('= Set Interval Time Remaining =');

            let token = JSON.parse( sessionStorage.getItem('apiToken')),
                expire = moment(token.expires),
                currentTime = moment(new Date()),
                timeLeft = expire.diff(currentTime, 'minutes');
                
            console.log('Expiration: ' + expire.format('LT'));
            console.log('Now: ' + currentTime.format('LT'));
            console.log('Time Remaining: ' + timeLeft);
            if (timeLeft > 15) {
                console.log('! - Check Time - !')
                console.log(token);
            }
            // Check refreshToken time remaining
            if (refreshTimeRemaining < 5) {
                this.updateRefreshToken = true;
            } 
            
            if (refreshTimeRemaining < 1){
                console.log('Logout process..')
                document.querySelector('.sign-out-button').click();
            }
            
        },

        logoutUser() {
            console.log('Logout process..')
            document.querySelector('.sign-out-button').click();
        },

        resetTimer() {
            clearInterval(this.warningTimer)
            clearTimeout(this.logoutTimer)
            this.setTimers();
            
            let refreshTimeRemaining = this.calculateTimeRemaining()
            // Check refreshToken time remaining
            if (refreshTimeRemaining < 5) {
                this.updateRefreshToken = true;
                
            }
        },
        
    },
    watch: {
        updateRefreshToken: function (val) {
            if(val === true){
                this.warningZone = true
            }
        }
    }
};
</script>

<style scoped lang="scss">
.modal-container {
    position:fixed;
    top:50%;
    left:50%;
    width: 300px;
   height: auto;
   background-color: white;
   padding: 20px;
   box-shadow:0px 0px 20px #000;;
   border-radius: 10px;
    z-index:1;
}

.modalAutoLogout{
}

.disabled-link {
  pointer-events: none;
  cursor: default;
  opacity: 0.6;
}
</style>